import CardPageVisits from "components/Cards/CardPageVisits";
import CardSocialTraffic from "components/Cards/CardSocialTraffic";
import CardStats from "components/Cards/CardStats";
import React from "react";
import { useHistory } from "react-router";

const HomeScreen = ({ estateData = {}, accessCreated = [] }) => {
  let history = useHistory();
  const residentsAdded = estateData?.residentsAdded;
  const residentsOnboarded = estateData.residentsOnboarded;
  const officersAdded = estateData?.officersAdded;
  const officersOnboarded = estateData.officersOnboarded;

  return (
    <div className="relative bg-gray-50 p-6 min-h-screen">
      <div className="mx-auto w-full h-full">
        <div>
          {/* Card stats */}
          <div className="flex flex-wrap">
            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
              onClick={() => {
                history.push("/residents");
              }}
            >
              <CardStats
                statSubtitle="RESIDENTS ADDED"
                statTitle={`${residentsAdded}`}
                statArrow="up"
                statPercent="3.48"
                statPercentColor="text-green-600"
                statDescripiron="Since last month"
                statIconName="fas fa-users"
                statIconColor="bg-red-500"
              />
            </div>

            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
              onClick={() => {
                history.push("/residents");
              }}
            >
              <CardStats
                statSubtitle="RESIDENTS ONBOARDED"
                statTitle={`${residentsOnboarded}`}
                statArrow="up"
                statPercent="3.48"
                statPercentColor="text-green-600"
                statDescripiron="Since last month"
                statIconName="fas fa-users"
                statIconColor="bg-green-500"
              />
            </div>

            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4  cursor-pointer"
              onClick={() => {
                history.push("/access-codes");
              }}
            >
              <CardStats
                statSubtitle="ACCESS CREATED"
                statTitle={`${estateData.accessCodesCreated}`}
                statArrow="down"
                statPercent="3.48"
                statPercentColor="text-red-500"
                statDescripiron="Since last week"
                statIconName="fas fa-lock"
                statIconColor="bg-indigo-500"
              />
            </div>
            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4  cursor-pointer"
              onClick={() => {
                history.push("/access-codes");
              }}
            >
              <CardStats
                statSubtitle="ACCESS PENDING VERIFICATION"
                statTitle={`${estateData.accessCodesCreated - (estateData.accessCodesGranted + estateData.accessCodesDenied)}`}
                statArrow="down"
                statPercent="1.10"
                statPercentColor="text-yellow-500"
                statDescripiron="Since yesterday"
                statIconName="fas fa-lock"
                statIconColor="bg-yellow-500"
              />
            </div>

            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
              onClick={() => {
                history.push("/officers");
              }}
            >
              <CardStats
                statSubtitle="OFFICERS ADDED"
                statTitle={`${officersAdded}`}
                statArrow="up"
                statPercent="3.48"
                statPercentColor="text-green-600"
                statDescripiron="Since last month"
                statIconName="fas fa-user-secret"
                statIconColor="bg-blue-500"
              />
            </div>
            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
              onClick={() => {
                history.push("/officers");
              }}
            >
              <CardStats
                statSubtitle="OFFICERS ONBOARDED"
                statTitle={`${officersOnboarded}`}
                statArrow="up"
                statPercent="3.48"
                statPercentColor="text-green-600"
                statDescripiron="Since last month"
                statIconName="fas fa-user-secret"
                statIconColor="bg-green-500"
              />
            </div>

            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4  cursor-pointer"
              onClick={() => {
                history.push("/access-codes");
              }}
            >
              <CardStats
                statSubtitle="ACCESS GRANTED"
                statTitle={`${estateData.accessCodesGranted}`}
                statArrow="down"
                statPercent="1.10"
                statPercentColor="text-yellow-500"
                statDescripiron="Since yesterday"
                statIconName="fas fa-lock"
                statIconColor="bg-green-500"
              />
            </div>
            <div
              className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4  cursor-pointer"
              onClick={() => {
                history.push("/access-codes");
              }}
            >
              <CardStats
                statSubtitle="ACCESS DENIED"
                statTitle={`${estateData.accessCodesDenied}`}
                statArrow="up"
                statPercent="12"
                statPercentColor="text-green-500"
                statDescripiron="Since last month"
                statIconName="fas fa-lock"
                statIconColor="bg-red-500"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-wrap mt-4">
          <div className="w-full xl:w-6/12 mb-12 xl:mb-0 px-4">
            <CardPageVisits accessCreated={accessCreated} />
          </div>
          <div className="w-full xl:w-6/12 px-4">
            <CardSocialTraffic accessCreated={accessCreated} estateData={estateData} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeScreen;
