import { createAction } from "redux-act";
import { toastr } from "react-redux-toastr";

import { firebaseError } from "utils";

import { newFirestore, newFunction, newStorage } from "../../utils/firebase";
import { deleteDocument, updateDocument } from "../api";
import { onSnapshot, orderBy, query } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { deleteObject, ref } from "firebase/storage";

export const USERS_FETCH_DATA_INIT = createAction("USERS_FETCH_DATA_INIT");
export const USERS_FETCH_DATA_SUCCESS = createAction("USERS_FETCH_DATA_SUCCESS");
export const USERS_FETCH_DATA_FAIL = createAction("USERS_FETCH_DATA_FAIL");

export const USERS_DELETE_USER_INIT = createAction("USERS_DELETE_USER_INIT");
export const USERS_DELETE_USER_SUCCESS = createAction("USERS_DELETE_USER_SUCCESS");
export const USERS_DELETE_USER_FAIL = createAction("USERS_DELETE_USER_FAIL");

export const USERS_CREATE_USER_INIT = createAction("USERS_CREATE_USER_INIT");
export const USERS_CREATE_USER_SUCCESS = createAction("USERS_CREATE_USER_SUCCESS");
export const USERS_CREATE_USER_FAIL = createAction("USERS_CREATE_USER_FAIL");

export const USERS_MODIFY_USER_INIT = createAction("USERS_MODIFY_USER_INIT");
export const USERS_MODIFY_USER_SUCCESS = createAction("USERS_MODIFY_USER_SUCCESS");
export const USERS_MODIFY_USER_FAIL = createAction("USERS_MODIFY_USER_FAIL");
export const USER_GET_ESTATE_DATA = createAction("USER_GET_ESTATE_DATA");

export const USERS_CLEAN_UP = createAction("USERS_CLEAN_UP");

export const USERS_CLEAR_DATA_LOGOUT = createAction("USERS_CLEAR_DATA_LOGOUT");

export const fetchUsers = (estateID) => {
  return async (dispatch) => {
    let users;

    try {
      onSnapshot(query(collection(newFirestore, `estates/${estateID}/residents`), orderBy("userCreateDate", "desc")), (querySnapshot) => {
        var allDocs = [];
        querySnapshot.forEach((doc) => {
          allDocs.push(doc.data());
        });
        users = allDocs;

        return dispatch(
          USERS_FETCH_DATA_SUCCESS({
            data: allDocs.map((user) => {
              // console.log(user?.dateCreated.toDate().toUTCString());
              return {
                ...user,
                latitude: user?.location?.latitude,
                longitude: user?.location?.longitude,
                userCreateDate: user?.userCreateDate.toDate().toUTCString(),
                userSignedInDate: user?.userSignedIn ? user?.userSignedInDate?.toDate().toUTCString() : "Not Yet",
              };
            }),
          })
        );
      });
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(USERS_FETCH_DATA_FAIL({ error }));
    }

    // console.log({ users });
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes("firebasestorage")) {
    return null;
  }
  const logoPath = oldLogo.split("users%2F").pop().split("?alt=media").shift();
  return deleteObject(ref(newStorage, `users/${logoPath}`));
};

export const deleteUser = (id) => {
  return async (dispatch, getState) => {
    dispatch(USERS_DELETE_USER_INIT());
    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .users.data.filter((user) => user.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteUserTask = deleteDocument("users", id);

    try {
      await Promise.all([deleteLogoTask, deleteUserTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_DELETE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success("", "The user was deleted.");
    return dispatch(USERS_DELETE_USER_SUCCESS({ id }));
  };
};

export const clearUsersDataLogout = () => {
  return (dispatch) => {
    dispatch(USERS_CLEAR_DATA_LOGOUT());
  };
};

// const uploadLogo = (uid, file) => {
//   const storageRef = storage().ref();

//   const fileExtension = file.name.split(".").pop();

//   const fileName = `${uid}.${fileExtension}`;

//   return storageRef.child(`users/${fileName}`).put(file);
// };

// const getLogoUrl = (uid, file) => {
//   const fileExtension = file.name.split(".").pop();

//   const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

//   return `${bucketUrl}/o/users%2F${uid}_200x200.${fileExtension}?alt=media`;
// };

export const createResident = (allData) => {
  return async (dispatch, getState) => {
    dispatch(USERS_CREATE_USER_INIT());
    const { locale } = getState().preferences;

    let response;
    try {
      const addNewUser = httpsCallable(newFunction, "addNewUser");
      response = await addNewUser(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);

      allData?.reset({
        name: "",
        emailAddress: "",
        houseNumber: "",
        userGroup: "",
        userType: "",
        phoneNumber: "",
        gender: "",
        adults: "",
        kids: "",
        checkIn: "",
        checkOut: "",
      });

      return dispatch(USERS_CREATE_USER_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: response.data?.response,
        })
      );
    }
  };
};

export const addAdmin = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_CREATE_USER_INIT());

    let response;
    try {
      const addNewAdmin = httpsCallable(newFunction, "addNewAdmin");
      response = await addNewAdmin(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response, { timeOut: 0 });
      return dispatch(USERS_CREATE_USER_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_CREATE_USER_FAIL(response.data?.response));
    }
  };
};

export const addEmergencyContact = (allData) => {
  return async (dispatch) => {
    dispatch(USERS_CREATE_USER_INIT());

    let response;
    try {
      const addNewEmergencyContact = httpsCallable(newFunction, "addNewEmergencyContact");

      response = await addNewEmergencyContact(allData);
    } catch (error) {
      const errorMessage = firebaseError(error.message);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_CREATE_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    if (response.data?.status) {
      toastr.success("", response.data?.response);
      return dispatch(USERS_CREATE_USER_SUCCESS());
    } else {
      toastr.error("", response.data?.response);
      return dispatch(USERS_CREATE_USER_FAIL(response.data?.response));
    }
  };
};

export const modifyUser = ({ name, emailAddress, id, userGroup, gender, streetName }, estateID) => {
  return async (dispatch, getState) => {
    dispatch(USERS_MODIFY_USER_INIT());
    const { locale } = getState().preferences;

    const userData = {
      name,
      estateID,
      userGroup,
      uid: id,
      id,
      emailAddress,
      gender,
      streetName,
    };

    const updateUserDbTask = updateDocument(`estates/${estateID}/residents`, id, userData);
    try {
      const updateUserDetails = httpsCallable(newFunction, "updateUserDetails");
      await updateUserDetails({ userData });
    } catch (error) {
      console.log(error);

      toastr.error("", error?.message);
    }

    try {
      await Promise.all([updateUserDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error("", errorMessage);
      return dispatch(
        USERS_MODIFY_USER_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success("", "User updated successfully");
    return dispatch(USERS_MODIFY_USER_SUCCESS({ user: userData, id }));
  };
};

export const usersCleanUp = () => (dispatch) => dispatch(USERS_CLEAN_UP());
