import { doc, getDoc, deleteDoc, setDoc, updateDoc } from "firebase/firestore";
import { newFirestore } from "../../utils/firebase";

export const fetchDocument = async (collection, id) => {
  const document = await getDoc(doc(newFirestore, collection, id));
  // console.log(document.data());
  if (!document.exists) {
    return null;
  }

  return { id: document.id, ...document.data() };
};

export const deleteDocument = (collection, id) => {
  return deleteDoc(doc(newFirestore, collection, id));
};

export const createDocument = (collection, id, values) => {
  return setDoc(doc(newFirestore, collection, id), values);
};

export const updateDocument = (collection, id, values) => {
  return updateDoc(doc(newFirestore, collection, id), values);
};
