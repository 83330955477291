import React from "react";
import classNames from "classnames";

import classes from "./Footer.module.scss";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className={classNames("level", classes.level)}>
          <div className="level-left">
            <div className="level-item">
              © {new Date().getFullYear()} <span>&nbsp; Lyme Drip Limited</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
