import { upperCase } from "lodash";
import paths from "pages/Router/paths";
import React from "react";
import { Link } from "react-router-dom";

const ArrivingOrders = ({ ordersList }) => {
  return (
    <div className="lg:fixed  right-0 top-0  bg-gray-300 lg:w-[250px] h-full lg:pt-[100px] overflow-y-scroll overflow-x-hidden pb-5 lg:pb-1">
      <h1 className="text-center font-bold">Arriving orders</h1>
      <h1 className="text-center leading-none mb-3">Click on order id to open order details</h1>
      {ordersList?.map((order) => {
        return (
          <div key={order?.id} className="flex justify-between items-center mb-1 px-5">
            <Link to={paths.ORDERS + `?order=${order.id}`} className="text-xs underline w-1/3 cursor-pointer">
              {order.id.substring(0, 5) + "..."}
            </Link>
            <p className="text-xs font-bold w-1/3">
              {order.currency}
              {order?.orderAmount}
            </p>
            <p
              className={`${
                ["closed"].includes(order?.status)
                  ? "text-green-900"
                  : ["cancelled"].includes(order?.status)
                  ? "text-red-500"
                  : ["ready_for_pickup", "ready_for_delivery"].includes(order?.status)
                  ? "text-yellow-600"
                  : ["paid"].includes(order?.status)
                  ? "text-green-500"
                  : ""
              } font-bold text-xs w-1/3`}
            >
              {upperCase(order?.status)}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default ArrivingOrders;
