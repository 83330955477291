import React from "react";

import { useSelector } from "react-redux";
import CardStats from "components/Cards/CardStats";
import { useHistory } from "react-router";
import CardBarChart from "components/Cards/CardBarChart";

const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

function generateRandomHexValue() {
  const randomValue = Math.floor(Math.random() * 16777215); // Generates a random number between 0 and FFFFFF in hexadecimal
  const hexValue = randomValue.toString(16).toUpperCase().padStart(6, "0"); // Convert to hex and pad with zeros
  return `#${hexValue}`;
}

function generateRandomHexValuesArray(count) {
  const hexValuesArray = [];
  for (let i = 0; i < count; i++) {
    hexValuesArray.push(generateRandomHexValue());
  }
  return hexValuesArray;
}

function filterItemsByDayOfWeekAndStatus(items, desiredDayOfWeek, desiredStatus) {
  const filteredItems = items.filter((item) => {
    const itemDayOfWeek = daysOfWeek[item.timestamp.getDay()];
    return itemDayOfWeek === desiredDayOfWeek && desiredStatus.includes(item.status);
  });

  return filteredItems?.length;
}

const Dashboard = ({ ordersList }) => {
  let history = useHistory();
  const { estateData, estateID, shopId, shopName } = useSelector((state) => ({
    userName: state.auth.userData.name,
    estateID: state.auth?.estateData?.estateID,
    estateData: state.auth?.estateData,
    shopId: state.auth.userData.shopId,
    shopName: state.auth.userData.shopName,
  }));

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <h1 className="title">{shopName} (Weekly Data)</h1>
        </div>
      </section>

      <section className="is-main-section">
        <div className="relative bg-gray-50">
          <div className="lg:w-[calc(100%-250px)] pt-3">
            <div className="flex flex-wrap">
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="RECEIVED"
                  statTitle={`${ordersList?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-yellow-500"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="PAID"
                  statTitle={`${
                    ordersList?.filter((o) =>
                      ["closed", "delivered", "paid", "ready_for_pickup", "accepted", "ready_for_delivery"]?.includes(o?.status)
                    )?.length
                  }`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-green-300"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="CANCELLED"
                  statTitle={`${ordersList?.filter((o) => o?.status === "cancelled")?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-red-500"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="PICKUP ORDERS"
                  statTitle={`${ordersList?.filter((o) => o?.orderType === "pickup")?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-indigo-300"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="DELIVERY ORDERS"
                  statTitle={`${ordersList?.filter((o) => o?.orderType === "delivery")?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-indigo-400"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="READY FOR PICKUP"
                  statTitle={`${ordersList?.filter((o) => o?.status === "ready_for_pickup")?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-yellow-100"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="READY FOR DELIVERY"
                  statTitle={`${ordersList?.filter((o) => o?.status === "delivered")?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-yellow-200"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-3/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardStats
                  statSubtitle="CLOSED"
                  statTitle={`${ordersList?.filter((o) => ["closed", "delivered"].includes(o?.status))?.length}`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-yellow-300"
                />
              </div>
              <div
                className="w-full lg:w-6/12 xl:w-6/12 px-4 mb-4 cursor-pointer"
                onClick={() => {
                  history.push("shop/orders");
                }}
              >
                <CardBarChart
                  statSubtitle="ORDERS"
                  statTitle={`ALL/PAID/CLOSED`}
                  statIconName="fas fa-cart-plus"
                  statIconColor="bg-indigo-400"
                  data={{
                    labels: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    datasets: [
                      {
                        label: "All",
                        backgroundColor: "gray",
                        borderColor: "gray",
                        data: daysOfWeek?.map((day) =>
                          filterItemsByDayOfWeekAndStatus(ordersList, day, [
                            "closed",
                            "delivered",
                            "paid",
                            "ready_for_pickup",
                            "new",
                            "cancelled",
                            "accepted",
                            "ready_for_delivery",
                          ])
                        ),
                        fill: false,
                      },
                      {
                        label: "Paid",
                        fill: false,
                        backgroundColor: "yellow",
                        borderColor: "yellow",
                        data: daysOfWeek?.map((day) =>
                          filterItemsByDayOfWeekAndStatus(ordersList, day, ["paid", "accepted", "ready_for_pickup", "ready_for_delivery"])
                        ),
                      },
                      {
                        label: "Cancelled",
                        fill: false,
                        backgroundColor: "red",
                        borderColor: "red",
                        data: daysOfWeek?.map((day) => filterItemsByDayOfWeekAndStatus(ordersList, day, ["cancelled"])),
                      },
                      {
                        label: "Closed",
                        fill: false,
                        backgroundColor: "green",
                        borderColor: "green",
                        data: daysOfWeek?.map((day) => filterItemsByDayOfWeekAndStatus(ordersList, day, ["delivered", "closed"])),
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
