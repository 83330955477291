import React, { Suspense, useEffect } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import NotFound from "pages/NotFound";
import paths from "./paths";
import { endOfWeek, startOfWeek } from "date-fns";
import { newFirestore } from "../../utils/firebase";
import { useSelector } from "react-redux";

import { HomePremium } from "pages/Home";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "pages/Dashboard";
import ArrivingOrders from "components/Dashboard/ArrivingOrders";
import { collection, onSnapshot, orderBy, query, where } from "firebase/firestore";

const Login = React.lazy(() => import("pages/Login"));
const Users = React.lazy(() => import("pages/Users"));
const Profile = React.lazy(() => import("pages/Profile"));
const ResetPassword = React.lazy(() => import("pages/ResetPassword"));
const User = React.lazy(() => import("pages/User"));
const EmergencyContact = React.lazy(() => import("pages/EmergencyContact"));
const EmergencyContacts = React.lazy(() => import("pages/EmergencyContacts"));
const AccessCodes = React.lazy(() => import("pages/AccessCodes"));
const Messages = React.lazy(() => import("pages/Messages"));
const ViewReports = React.lazy(() => import("pages/Reports/ViewReports"));
const Officers = React.lazy(() => import("pages/Officers"));
const AddAdmin = React.lazy(() => import("pages/AddAdmin"));
const Admins = React.lazy(() => import("pages/Admins"));
const Shop = React.lazy(() => import("pages/Shop"));
const EstateLevy = React.lazy(() => import("pages/Finance/EstateLevy"));
const Projects = React.lazy(() => import("pages/Finance/Projects"));
const Vehicles = React.lazy(() => import("pages/Finance/Vehicles"));
const AddNewLevyForm = React.lazy(() => import("pages/Finance/AddNewLevy"));
const AddNewProject = React.lazy(() => import("pages/Finance/AddNewProject"));
const Inventory = React.lazy(() => import("pages/Inventory"));
const Orders = React.lazy(() => import("pages/Orders"));
const Transactions = React.lazy(() => import("pages/Transactions"));
const ShopSetup = React.lazy(() => import("pages/ShopSetup"));
const Shops = React.lazy(() => import("pages/Shops"));
const Service = React.lazy(() => import("pages/Service"));
const Services = React.lazy(() => import("pages/Services"));
const Chats = React.lazy(() => import("pages/Chats"));
const Variances = React.lazy(() => import("pages/Variances"));
const Reports = React.lazy(() => import("pages/Reports"));
const HotelDashboard = React.lazy(() => import("pages/Hotel/Dashboard"));
const Section = React.lazy(() => import("pages/Hotel/NewSection"));
const Visitors = React.lazy(() => import("pages/Hotel/Visitors"));
const Security = React.lazy(() => import("pages/Hotel/Security"));
const Sections = React.lazy(() => import("pages/Hotel/Sections"));
const AddUser = React.lazy(() => import("pages/Hotel/AddUser"));

const RouterComponent = () => {
  const { estateData, claims, estateID, shopId } = useSelector((state) => ({
    estateData: state.auth?.estateData,
    claims: state.auth?.userData?.claims,
    userName: state.auth.userData.name,
    estateID: state.auth?.estateData?.estateID,
    shopId: state.auth.userData.shopId,
  }));

  // const { data: ordersList = [], error } = useCollection(`shops/${shopId}/orders`, {
  //   where: [
  //     ["verified", "==", true],
  //     ["timestamp", ">=", startOfWeek(new Date())],
  //     ["timestamp", "<=", endOfWeek(new Date())],
  //   ],
  //   orderBy: [["timestamp", "desc"]],
  //   parseDates: ["timestamp", "updated"],
  //   listen: true,
  // });

  const [ordersList, setOrdersList] = React.useState([]);
  useEffect(() => {
    let unsubscribe;

    if (claims?.userType?.includes("shop_")) {
      unsubscribe = onSnapshot(
        query(
          collection(newFirestore, `shops/${shopId}/orders`),
          where("verified", "==", true),
          where("timestamp", ">=", startOfWeek(new Date())),
          where("timestamp", "<=", endOfWeek(new Date())),
          orderBy("timestamp", "desc")
        ),

        (querySnapshot) => {
          var docs = [];
          querySnapshot.forEach((doc) => {
            const data = doc?.data();
            const timestamp = data?.timestamp?.toDate();
            docs.push({ ...data, timestamp });
          });

          setOrdersList(docs);
        }
      );
    }

    return unsubscribe;
  }, [claims]);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <BrowserRouter>
        <Switch>
          <Route exact path={paths.LOGIN} component={Login} />
          <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
          <PrivateRoute path={paths.ADD_USER} component={User} />
          <PrivateRoute path={paths.MODIFY_USER} component={User} />
          <PrivateRoute path={paths.ADD_EMERGENCY_CONTACT} component={EmergencyContact} />
          <PrivateRoute path={paths.RESIDENTS} component={Users} />
          <PrivateRoute path={paths.OFFICERS} component={Officers} />
          <PrivateRoute path={paths.EMERGENCY_CONTACTS} component={EmergencyContacts} />
          <PrivateRoute path={paths.ADMINS} component={Admins} />
          <PrivateRoute path={paths.SHOPS} component={Shops} />
          <PrivateRoute path={paths.ACCESSCODES} component={AccessCodes} />
          <PrivateRoute path={paths.MESSAGES} component={Messages} />
          <PrivateRoute path={paths.CHATS} component={Chats} />
          <PrivateRoute path={paths.PROFILE} component={Profile} />
          <PrivateRoute path={paths.VIEWREPORTS} component={ViewReports} />
          <PrivateRoute path={paths.ADDNEWLEVY} component={AddNewLevyForm} />
          <PrivateRoute path={paths.ADDNEWPROJECT} component={AddNewProject} />
          <PrivateRoute path={paths.PROJECTS} component={Projects} />
          <PrivateRoute path={paths.VEHICLES} component={Vehicles} />
          <PrivateRoute path={paths.ESTATELEVY} component={EstateLevy} />
          <PrivateRoute path={paths.REPORTS} component={Reports} />

          {/* service paths */}
          <PrivateRoute path={paths.ADD_NEW_SERVICE_PERSON} component={Service} />
          <PrivateRoute path={paths.EDIT_NEW_SERVICE_PERSON} component={Service} />

          {/* shop paths */}
          <PrivateRoute path={paths.ADDNEWSHOP} component={Shop} />
          <PrivateRoute path={paths.INVENTORY} component={Inventory} />
          <PrivateRoute path={paths.VARIANCES} component={Variances} />
          <PrivateRoute path={paths.ORDERS} component={Orders} />
          <PrivateRoute path={paths.TRANSACTIONS} component={Transactions} />
          <PrivateRoute path={paths.SHOP_SETUP} component={ShopSetup} />
          <PrivateRoute path={paths.ADD_ADMINS} component={AddAdmin} />
          <PrivateRoute path={paths.SERVICES} component={Services} />

          {/* hotel paths */}
          <PrivateRoute path={paths.ADDNEWSECTION} component={Section} />
          <PrivateRoute path={paths.VISITORS} component={Visitors} />
          <PrivateRoute path={paths.SECURITY} component={Security} />
          <PrivateRoute path={paths.SECTIONS} component={Sections} />
          <PrivateRoute path={paths.ADD_HOTEL_USER} component={AddUser} />

          {/* root */}
          <PrivateRoute
            path={paths.ROOT}
            component={
              claims?.userType?.includes("shop_")
                ? () => <Dashboard {...{ ordersList }} />
                : claims?.userType?.includes("hotel_")
                ? HotelDashboard
                : HomePremium
            }
          />
          <Route component={NotFound} />
        </Switch>
        {claims?.userType?.includes("shop_") && <ArrivingOrders {...{ ordersList }} />}
      </BrowserRouter>
    </Suspense>
  );
};

export default RouterComponent;
