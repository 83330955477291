export default {
  ROOT: "/",
  LOGIN: "/login",
  RESIDENTS: "/residents",
  OFFICERS: "/officers",
  ACCESSCODES: "/access-codes",
  ADD_ADMINS: "/admin/new",
  ADMINS: "/admins",
  EMERGENCY_CONTACTS: "/emergency-contacts",
  ADD_USER: "/users/new",
  ADD_EMERGENCY_CONTACT: "/emergency-contacts/new",
  MODIFY_USER: "/users/:id",
  PROFILE: "/profile",
  RESET_PASSWORD: "/recover-password",
  SECTION: "/section",
  VIEWREPORTS: "/reports/view",
  ESTATELEVY: "/estate-levy",
  ADDNEWLEVY: "/estate-levy/add-new-levy",
  PROJECTS: "/projects",
  VEHICLES: "/vehicle-payments",
  ADDNEWPROJECT: "/projects/manage-projects",
  MESSAGES: "/messages",
  CHATS: "/chats",
  SHOPS: "/shops",
  ADDNEWSHOP: "/shop/new",
  INVENTORY: "/shop/inventory",
  VARIANCES: "/shop/variances",
  ORDERS: "/shop/orders",
  TRANSACTIONS: "/shop/transactions",
  SHOP_SETUP: "/shop/setup",
  SHOP_ADD_ADMIN: "/shop/admin",
  SERVICES: "/services",
  ADD_NEW_SERVICE_PERSON: "/services/new",
  EDIT_NEW_SERVICE_PERSON: "/services/:id",
  REPORTS: "/reports",
  VISITORS: "/visitors",
  SECURITY: "/security",
  ADMINSANDSECTIONOWNERS: "/admins-and-section-owners",
  SECTIONS: "/sections",
  ADDNEWSECTION: "/sections/new",
  ADD_HOTEL_USER: "/user/new",
};
