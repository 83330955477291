import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import paths from "pages/Router/paths";
import NavLink from "../Link";
import classes from "./Aside.module.scss";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { newFirestore } from "utils/firebase";
import { doc } from "firebase/firestore";

export const SubMenu = ({ label, children, icon = "view-list" }) => {
  const [active, setActive] = useState(false);

  return (
    <li className={classNames({ "is-active": active })}>
      <a exact-active-class="is-active" className="has-icon has-dropdown-icon !text-white" onClick={() => setActive(!active)}>
        <span className="icon">
          <i className={`mdi mdi-${icon}`} />
        </span>
        <span className="menu-item-label">{label}</span>
        <div className="dropdown-icon">
          <span className="icon">
            <i className={classNames("mdi", { "mdi-minus": active }, { "mdi-plus": !active })} />
          </span>
        </div>
      </a>
      <ul>{children}</ul>
    </li>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const Aside = ({ handleMobileToggle }) => {
  const { type, estateID } = useSelector(
    (state) => ({
      type: state.auth.userData.userType,
      estateID: state.auth?.estateData?.estateID,
    }),
    shallowEqual
  );

  const [estateData = {}] = useDocumentData(doc(newFirestore, `estates/${estateID}`));

  return (
    <aside className="aside !bg-brandIceberg !text-white is-placed-left is-expanded min-h-screen overflow-y-scroll">
      <Link to={paths.ROOT} className="aside-tools !bg-brandIceberg !text-white">
        <div className="aside-tools-label">
          <span>
            Our <b>Gatekeeper</b>
          </span>
        </div>
      </Link>

      <div className="menu is-menu-main">
        <ul className="menu-list">
          <li>
            <NavLink to={paths.ROOT} className="has-icon !text-white" onClick={handleMobileToggle}>
              <span className="icon">
                <i className="mdi mdi-home" />
              </span>
              <span className="menu-item-label">Dashboard</span>
            </NavLink>
          </li>

          {!["shop_superuser"].includes(type) && (
            <>
              {estateData?.packagePermissions?.includes("showAdminResidents") && (
                <li>
                  <NavLink to={paths.RESIDENTS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="mdi mdi-account-supervisor" />
                    </span>
                    <span className="menu-item-label !text-white">Residents</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showHotelVisitors") && (
                <li>
                  <NavLink to={paths.VISITORS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="mdi mdi-account-supervisor" />
                    </span>
                    <span className="menu-item-label !text-white">Visitors</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showHotelSecurity") && (
                <li>
                  <NavLink to={paths.SECURITY} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="mdi mdi-account-supervisor" />
                    </span>
                    <span className="menu-item-label !text-white">Security</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showAdminOfficers") && (
                <li>
                  <NavLink to={paths.OFFICERS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="mdi mdi-account-supervisor" />
                    </span>
                    <span className="menu-item-label !text-white">Officers</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showAdminEmergencyContacts") && (
                <li>
                  <NavLink to={paths.EMERGENCY_CONTACTS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-ambulance"></i>
                    </span>
                    <span className="menu-item-label !text-white">Emergency Contacts</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showAdminAdmins") && (
                <li>
                  <NavLink to={paths.ADMINS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-users"></i>
                    </span>
                    <span className="menu-item-label !text-white">Admins</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showHotelAdminsAndSectionOWners") && (
                <li>
                  <NavLink to={paths.ADMINSANDSECTIONOWNERS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-users"></i>
                    </span>
                    <span className="menu-item-label !text-white">Admin/Section Owners</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showHotelSections") && (
                <li>
                  <NavLink to={paths.SECTIONS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-users"></i>
                    </span>
                    <span className="menu-item-label !text-white">Sections</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showAdminShops") && (
                <li>
                  <NavLink to={paths.SHOPS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-shopping-cart"></i>
                    </span>
                    <span className="menu-item-label !text-white">Shops</span>
                  </NavLink>
                </li>
              )}

              {estateData?.packagePermissions?.includes("showAdminServices") && (
                <li>
                  <NavLink to={paths.SERVICES} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-wrench"></i>
                    </span>
                    <span className="menu-item-label !text-white">Services</span>
                  </NavLink>
                </li>
              )}

              {
                <li>
                  <NavLink to={paths.REPORTS} className="has-icon !text-white" onClick={handleMobileToggle}>
                    <span className="icon">
                      <i className="fas fa-signal"></i>
                    </span>
                    <span className="menu-item-label !text-white">Reports</span>
                  </NavLink>
                </li>
              }
            </>
          )}

          <SubMenu label={`Manage`}>
            {["superuser", "hotel_superuser"].includes(type) && (
              <li>
                <NavLink
                  className={classes.submenuLink + " !text-white"}
                  to={["hotel"]?.includes(estateData?.type) ? paths.ADD_HOTEL_USER : paths.ADD_USER}
                  onClick={handleMobileToggle}
                >
                  <span>{`Add User`}</span>
                </NavLink>
              </li>
            )}

            {["shop_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.INVENTORY} onClick={handleMobileToggle}>
                  <span>{`Inventory`}</span>
                </NavLink>
              </li>
            )}

            {["shop_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.VARIANCES} onClick={handleMobileToggle}>
                  <span>{`Variants`}</span>
                </NavLink>
              </li>
            )}

            {["shop_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ORDERS} onClick={handleMobileToggle}>
                  <span>{`Orders`}</span>
                </NavLink>
              </li>
            )}

            {["shop_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.TRANSACTIONS} onClick={handleMobileToggle}>
                  <span>{`Transactions`}</span>
                </NavLink>
              </li>
            )}

            {["shop_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.SHOP_SETUP} onClick={handleMobileToggle}>
                  <span>{`Setup Shop`}</span>
                </NavLink>
              </li>
            )}

            {estateData?.packagePermissions?.includes("addAdminOfficer") &&
              ["superuser", "hotel_superuser", "shop_superuser"].includes(type) && (
                <li>
                  <NavLink className={classes.submenuLink + " !text-white"} to={paths.ADD_ADMINS} onClick={handleMobileToggle}>
                    <span>{`${
                      ["shop_superuser"].includes(type)
                        ? "Add Shop Admin"
                        : ["hotel_superuser"].includes(type)
                        ? "Add Admin/Section Owner "
                        : "Add Admin"
                    }`}</span>
                  </NavLink>
                </li>
              )}

            {["hotel_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ADDNEWSECTION} onClick={handleMobileToggle}>
                  <span>{`Add New Section`}</span>
                </NavLink>
              </li>
            )}

            {["superuser"].includes(type) && estateData?.packagePermissions?.includes("showAdminAddShop") && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ADDNEWSHOP} onClick={handleMobileToggle}>
                  <span>{`Add New Shop`}</span>
                </NavLink>
              </li>
            )}

            {["superuser"].includes(type) && estateData?.packagePermissions?.includes("showAdminAddServicePerson") && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ADD_NEW_SERVICE_PERSON} onClick={handleMobileToggle}>
                  <span>{`Add New Service Person`}</span>
                </NavLink>
              </li>
            )}

            {["superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ADD_EMERGENCY_CONTACT} onClick={handleMobileToggle}>
                  <span>{`Add Emergency Number`}</span>
                </NavLink>
              </li>
            )}

            {estateData?.packagePermissions?.includes("viewAccessCodeReports") && ["superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.ACCESSCODES} onClick={handleMobileToggle}>
                  <span>Access Codes Report</span>
                </NavLink>
              </li>
            )}

            {["superuser", "hotel_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.MESSAGES} onClick={handleMobileToggle}>
                  <span>Messages</span>
                </NavLink>
              </li>
            )}

            {["superuser", "hotel_superuser"].includes(type) && (
              <li>
                <NavLink className={classes.submenuLink + " !text-white"} to={paths.CHATS} onClick={handleMobileToggle}>
                  <span>Chats</span>
                </NavLink>
              </li>
            )}
          </SubMenu>

          {["ninja"].includes(type) && (
            <SubMenu label={`Reports`}>
              <li>
                <NavLink className={classes.submenuLink} to={paths.VIEWREPORTS} onClick={handleMobileToggle}>
                  <span>{`View`}</span>
                </NavLink>
              </li>
            </SubMenu>
          )}

          {["superuser", "hotel_superuser"].includes(type) && estateData?.packagePermissions?.includes("showAdminFinance") && (
            <SubMenu label={`Finance`} icon="cash">
              {estateData?.packagePermissions?.includes("showAdminLevy") && (
                <li>
                  <NavLink className={classes.submenuLink + " !text-white"} to={paths.ESTATELEVY} onClick={handleMobileToggle}>
                    <span>Estate Levy</span>
                  </NavLink>
                </li>
              )}
              {estateData?.packagePermissions?.includes("showAdminProjects") && (
                <li>
                  <NavLink className={classes.submenuLink + " !text-white"} to={paths.PROJECTS} onClick={handleMobileToggle}>
                    <span>Projects</span>
                  </NavLink>
                </li>
              )}
              {estateData?.packagePermissions?.includes("collectVehicleDetails") && (
                <li>
                  <NavLink className={classes.submenuLink + " !text-white"} to={paths.VEHICLES} onClick={handleMobileToggle}>
                    <span>Vehicle Payments</span>
                  </NavLink>
                </li>
              )}
            </SubMenu>
          )}
        </ul>
      </div>
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
};

export default Aside;
