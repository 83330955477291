import { createIntl, createIntlCache } from "react-intl";
import { newAuth } from "./firebase";

import english from "languages/en";
import spanish from "languages/es";
import en from "assets/en.png";
import es from "assets/es.png";

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: "auth/email-already-exists",
  EMAIL_INVALID: "auth/invalid-email",
  EMAIL_NOT_FOUND: "auth/user-not-found",
  PASSWORD_INVALID: "auth/wrong-password",
  USER_DISABLED: "auth/user-disabled",
  TOO_MANY_REQUESTS: "auth/too-many-requests",
  EXPIRED_ACTION_CODE: "auth/expired-action-code",
  INVALID_ACTION_CODE: "auth/invalid-action-code",
  QUOTA_EXCEEDED_STORAGE: "storage/quota-exceeded",
  UNAUTHENTICATED_STORAGE: "storage/unauthenticated",
  UNAUTHORIZED_STORAGE: "storage/unauthorized",
};

export const messages = {
  en: english,
  es: spanish,
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale = "en") => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]["utils.default"],
  });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  es,
};

export const uiConfig = (onSignInSuccessHandler, onSignInFailHandler) => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: "popup",
    signInSuccessUrl: "/home",
    signInOptions: [
      {
        provider: newAuth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Google",
        scopes: ["https://www.googleapis.com/auth/user.addresses.read", "https://www.googleapis.com/auth/userinfo.email"],
      },
      {
        provider: newAuth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: "Continue with Facebook",
        scopes: ["email"],
      },
      { provider: "microsoft.com", fullLabel: "Continue with Microsoft" },
    ],
  };
};

export const genRandomString = (length = 10) => {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charLen = parseInt(length);

  for (var i = 0; i < charLen; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text.toUpperCase();
};

export const sendExpoNotificaiton = async (data = [], title, body) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      to: data,
      title,
      body,
      priority: "high",
      sound: "default",
      badge: 0,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch("https://thingproxy.freeboard.io/fetch/https://exp.host/--/api/v2/push/send", requestOptions);
    return response.text();
  } catch (error) {
    console.log(error);
  }
};

export const getTableDataForExport = (data, columns, headerName = "Header") => {
  return data?.map((record) =>
    columns.reduce((recordToDownload, column) => ({ ...recordToDownload, [column[headerName]]: record[column.accessor] }), {})
  );
};

export const CSVOptions = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: true,
  // title: estateData?.name,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  // filename: estateData?.name,
  // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
};

export function checkstuff(event) {
  if (event.target.value.substr(-1) === " " && event.code === "Space") {
    event.target.value = event.target.value.substr(0, event.target.value.length - 1);
  }

  var evt = event ? event : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;

  if (charCode > 32 && (charCode < 65 || charCode > 90) && (charCode < 97 || charCode > 122)) {
    console.log(charCode);
    return false;
  }

  return true;
}

export const onFileChange = async (e) => {
  if (e.target.files && e.target.files.length > 0) {
    const file = e.target.files[0];
    let imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);
  }
};

export function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export const DEFAULT_ITEM_IMAGE_PATH = `https://storage.googleapis.com/${process.env.REACT_APP_FIRE_BASE_STORAGE_BUCKET}/default_item.jpeg`;
