import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import ReduxToastr from "react-redux-toastr";
import LanguageWrapper from "components/LanguageWrapper";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { configureStore } from "./state/store";
import "./index.css";
import "react-phone-number-input/style.css";
import Router from "./pages/Router";

import { ToastProvider } from "react-toast-notifications";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/main.css";
import { createRoot } from "react-dom/client";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const { store, persistor } = configureStore({});

const app = (
  <ToastProvider placement="top-center">
    <Provider store={store}>
      <LanguageWrapper>
        <PersistGate persistor={persistor}>
          <ReduxToastr
            newestOnTop={false}
            preventDuplicates
            position="top-center"
            getState={(state) => state.toastr}
            transitionIn="fadeIn"
            transitionOut="fadeOut"
            progressBar
            closeOnToastrClick
          />
          <Router />
          <Analytics />
          <SpeedInsights />
        </PersistGate>
      </LanguageWrapper>
    </Provider>
  </ToastProvider>
);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(app);
