import { createAction } from "redux-act";
import { toastr } from "react-redux-toastr";

import { firebaseError } from "utils";

import { newAuth, newFirestore } from "../../utils/firebase";
import { clearUsersDataLogout } from "./users";
import { createDocument, fetchDocument } from "../api";
import {
  getMultiFactorResolver,
  onAuthStateChanged,
  PhoneAuthProvider,
  reauthenticateWithCredential,
  RecaptchaVerifier,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { collection, doc, onSnapshot } from "firebase/firestore";

export const AUTH_SIGN_IN_INIT = createAction("AUTH_SIGN_IN_INIT");
export const AUTH_SIGN_IN_FAIL = createAction("AUTH_SIGN_IN_FAIL");

export const AUTH_RESTORE_SESSION_INIT = createAction("AUTH_RESTORE_SESSION_INIT");
export const AUTH_RESTORE_SESSION_SUCCESS = createAction("AUTH_RESTORE_SESSION_SUCCESS");
export const AUTH_RESTORE_SESSION_FAIL = createAction("AUTH_RESTORE_SESSION_FAIL");

export const AUTH_LOGOUT_INIT = createAction("AUTH_LOGOUT_INIT");
export const AUTH_LOGOUT_SUCCESS = createAction("AUTH_LOGOUT_SUCCESS");

export const AUTH_SET_PASSWORD_INIT = createAction("AUTH_SET_PASSWORD_INIT");
export const AUTH_SET_PASSWORD_SUCCESS = createAction("AUTH_SET_PASSWORD_SUCCESS");
export const AUTH_SET_PASSWORD_FAIL = createAction("AUTH_SET_PASSWORD_FAIL");

export const AUTH_RESET_PASSWORD_INIT = createAction("AUTH_RESET_PASSWORD_INIT");
export const AUTH_RESET_PASSWORD_SUCCESS = createAction("AUTH_RESET_PASSWORD_SUCCESS");
export const AUTH_RESET_PASSWORD_FAIL = createAction("AUTH_RESET_PASSWORD_FAIL");

export const AUTH_CLEAN_UP = createAction("AUTH_CLEAN_UP");

export const AUTH_FETCH_USER_DATA_INIT = createAction("AUTH_FETCH_USER_DATA_INIT");
export const AUTH_FETCH_USER_DATA_SUCCESS = createAction("AUTH_FETCH_USER_DATA_SUCCESS");
export const AUTH_FETCH_USER_DATA_FAIL = createAction("AUTH_FETCH_USER_DATA_FAIL");

export const AUTH_CHANGE_PASSWORD_INIT = createAction("AUTH_CHANGE_PASSWORD_INIT");
export const AUTH_CHANGE_PASSWORD_SUCCESS = createAction("AUTH_CHANGE_PASSWORD_SUCCESS");
export const AUTH_CHANGE_PASSWORD_FAIL = createAction("AUTH_CHANGE_PASSWORD_FAIL");

export const AUTH_UPDATE_USER_DATA = createAction("AUTH_UPDATE_USER_DATA");

export const AUTH_PROVIDER_INIT = createAction("AUTH_PROVIDER_INIT");

export const AUTH_PROVIDER_SUCCESS = createAction("AUTH_PROVIDER_SUCCESS");

export const AUTH_PROVIDER_FAIL = createAction("AUTH_PROVIDER_FAIL");
export const AUTH_GET_ESTATE_FAIL = createAction("AUTH_GET_ESTATE_FAIL");
export const AUTH_GET_ESTATE_SUCCESS = createAction("AUTH_GET_ESTATE_SUCCESS");
export const AUTH_GET_APP_CONFIG_SUCCESS = createAction("AUTH_GET_APP_CONFIG_SUCCESS");
export const VERIFY_USER = createAction("VERIFY_USER");

export const logout = () => {
  return async (dispatch) => {
    dispatch(AUTH_LOGOUT_INIT());

    dispatch(clearUsersDataLogout());
    await signOut(newAuth);

    dispatch(AUTH_LOGOUT_SUCCESS());
  };
};

export const verifyAuth = () => {
  return (dispatch) => {
    onAuthStateChanged(newAuth, (user) => {
      dispatch(AUTH_RESTORE_SESSION_INIT());

      if (user !== null) {
        return dispatch(AUTH_RESTORE_SESSION_SUCCESS());
      }

      dispatch(AUTH_RESTORE_SESSION_FAIL());
      return dispatch(logout());
    });
  };
};

export const fetchEstateData = (estateID) => {
  return async (dispatch) => {
    try {
      onSnapshot(doc(newFirestore, "estates", estateID), (doc) => {
        return dispatch(AUTH_GET_ESTATE_SUCCESS(doc.data()));
      });
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(AUTH_GET_ESTATE_FAIL({ error }));
    }
  };
};

export const fetchAppConfigurations = () => {
  return async (dispatch) => {
    try {
      onSnapshot(collection(newFirestore, "estates"), (querySnapshot) => {
        var configs = {};
        querySnapshot.forEach((doc) => {
          configs[doc.id] = doc.data();
        });

        return dispatch(AUTH_GET_APP_CONFIG_SUCCESS(configs));
      });
    } catch (error) {
      console.log(error);
      toastr.error("", error);
      return dispatch(AUTH_GET_ESTATE_FAIL({ error }));
    }
  };
};

export const fetchUserData = () => {
  return async (dispatch) => {
    dispatch(AUTH_FETCH_USER_DATA_INIT());
    const { uid } = newAuth.currentUser;

    const userClaims = await fetchDocument(`userClaims`, uid);

    let user;

    try {
      user = await fetchDocument(`estates/${userClaims?.estateID}/admins`, uid);

      if (!user) {
        return dispatch(logout());
      }

      await dispatch(fetchEstateData(userClaims?.estateID));
      await dispatch(fetchAppConfigurations());
    } catch (error) {
      dispatch(logout());
      return dispatch(AUTH_FETCH_USER_DATA_FAIL({ error }));
    }

    return dispatch(
      AUTH_FETCH_USER_DATA_SUCCESS({
        ...user,
        claims: userClaims,
        dateAdded: user?.dateAdded?.toDate().toUTCString(),
      })
    );
  };
};

export const checkUserData = () => {
  return (dispatch, getState) => {
    const { id } = getState().auth.userData;

    if (!id) {
      dispatch(fetchUserData());
    }
  };
};

export const startMfaSignin = async (multiFactorHint, session) => {
  try {
    // const recaptchaVerifier = new RecaptchaVerifier("recaptcha", undefined, newAuth);
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha",
      {
        size: "invisible",
      },
      newAuth
    );

    const phoneInfoOptions = {
      multiFactorHint: multiFactorHint,
      session: session,
    };
    const phoneAuthProvider = new PhoneAuthProvider(newAuth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    return verificationId;
  } catch (error) {
    console.log(error);
  }
};

export const auth = (email, password) => {
  return async (dispatch, getState) => {
    try {
      dispatch(AUTH_SIGN_IN_INIT());
      try {
        await signInWithEmailAndPassword(newAuth, email, password);
      } catch (error) {
        console.log(error.message);

        if (error.code == "auth/multi-factor-auth-required") {
          const resolver = getMultiFactorResolver(newAuth, error);
          const { phoneNumber } = resolver.hints[0];
          const verificationId = await startMfaSignin(resolver.hints[0], resolver.session);
          dispatch(VERIFY_USER({ verify: true, verificationId, error }));
          return toastr.success("", `OTP sent to ${phoneNumber}`);
        }

        return dispatch(AUTH_SIGN_IN_FAIL({ error: error.message }));
      }

      return dispatch(fetchUserData());
    } catch (error) {
      console.log(error);
    }
  };
};

export const resetPassword = (email) => {
  return async (dispatch, getState) => {
    dispatch(AUTH_RESET_PASSWORD_INIT());
    const { locale } = getState().preferences;

    try {
      await sendPasswordResetEmail(newAuth, email);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      return dispatch(AUTH_RESET_PASSWORD_FAIL({ error: errorMessage }));
    }

    return dispatch(AUTH_RESET_PASSWORD_SUCCESS());
  };
};

export const authCleanUp = () => (dispatch) => dispatch(AUTH_CLEAN_UP());
