import React, { useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PropTypes from "prop-types";

import { addMinutes, isAfter } from "date-fns";
import { logout } from "state/actions/auth";
import { toastr } from "react-redux-toastr";
import Layout from "components/Layout";
import paths from "../paths";

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const PrivateRoute = ({ path, component: Component }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { id } = useSelector(
    (state) => ({
      id: state.auth.userData.id,
    }),
    shallowEqual
  );

  useEffect(() => {
    // console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === "production") {
      document.addEventListener("click", (event) => {
        const timeoutKey = localStorage.getItem("@our-gatekeeper/admin.timeout");

        if (!timeoutKey) {
          const oneMinuteAfter = addMinutes(new Date(), 60);
          const toISO = oneMinuteAfter.toISOString();
          localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
        } else {
          const toDate = new Date(timeoutKey);
          const trueIsAfter = isAfter(toDate, new Date());
          if (!trueIsAfter) {
            toastr.error("Your session has expired. Please login again!!");
            dispatch(logout());
            const oneMinuteAfter = addMinutes(new Date(), 60);
            const toISO = oneMinuteAfter.toISOString();
            localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
          } else {
            const oneMinuteAfter = addMinutes(new Date(), 60);
            const toISO = oneMinuteAfter.toISOString();
            localStorage.setItem("@our-gatekeeper/admin.timeout", toISO);
          }
        }
      });
    }
  }, []);

  return (
    <Layout>
      <Route
        exact
        path={path}
        // component={Component}
        render={() => (id ? <Component /> : <Redirect to={paths.LOGIN} />)}
      />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired,
};

export default PrivateRoute;
