import React, { useState, useCallback } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import classNames from "classnames";
import PropTypes from "prop-types";

import { availableLocales, flags } from "utils";
import { setUserLocale } from "state/actions/preferences";
import { useFormatMessage } from "hooks";
import { logout } from "state/actions/auth";
import paths from "pages/Router/paths";
import defaultLogo from "assets/user-default-log.svg";
import Link from "../Link";

const NavBar = ({ handleMobileToggle, asideMobileActive }) => {
  const [navMobileActive, setNavMobileActive] = useState(false);

  const { userName, estateData } = useSelector(
    (state) => ({
      userName: state.auth.userData.name,
      estateID: state.auth?.estateData?.estateID,
      estateData: state.auth?.estateData,
      logoUrl: state.auth.userData.logoUrl,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const onClickLogoutHandler = () => {
    dispatch(logout());
  };
  const onMobileToggleHandler = useCallback(() => {
    setNavMobileActive(!navMobileActive);
  }, [setNavMobileActive, navMobileActive]);

  return (
    <nav id="navbar-main" className="navbar is-fixed-top">
      <div className="navbar-brand">
        <a className="navbar-item is-hidden-desktop jb-aside-mobile-toggle" onClick={handleMobileToggle}>
          <span className="icon">
            <i
              className={classNames(
                "mdi",
                "mdi-24px",
                { "mdi-forwardburger": !asideMobileActive },
                { "mdi-backburger": asideMobileActive }
              )}
            />
          </span>
        </a>
      </div>

      <div className="navbar-brand is-right">
        <a className="navbar-item is-hidden-desktop jb-navbar-menu-toggle" data-target="navbar-menu" onClick={onMobileToggleHandler}>
          <span className="icon">
            <i className={classNames("mdi", { "mdi-dots-vertical": !navMobileActive }, { "mdi-close": navMobileActive })} />
          </span>
        </a>
      </div>

      <div
        className={classNames("navbar-menu", "fadeIn", "animated", "faster", {
          "is-active": navMobileActive,
        })}
        id="navbar-menu"
      >
        <div className="flex flex-col justify-center pl-6">
          <div className="flex items-center">
            <img src={estateData?.appConfigurations?.logo} alt="logo" className="w-10 h-10 lg:w-10 lg:h-10 rounded mr-2 mt-1" />
            <div>
              <p className="text-2xl font-bold">{estateData?.name}</p>
              <p className="">{estateData?.packageName} package</p>
            </div>
          </div>
        </div>
        <div className="navbar-end">
          <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
            <a className="navbar-link is-arrowless">
              <div className="is-user-avatar">
                <img src={`/icon.png`} alt="User profile" />
              </div>
              <div className="is-user-name">
                <span>{userName}</span>
              </div>
              <span className="icon">
                <i className="mdi mdi-chevron-down" />
              </span>
            </a>

            <div className="navbar-dropdown">
              <Link to={paths.PROFILE} onClick={onMobileToggleHandler}>
                <span className="icon">
                  <i className="mdi mdi-account" />
                </span>
                <span>{useFormatMessage("NavBar.profile")}</span>
              </Link>
              <hr className="navbar-divider" />
              <a className="navbar-item" id="logout" onClick={onClickLogoutHandler}>
                <span className="icon">
                  <i className="mdi mdi-logout" />
                </span>
                <span>{useFormatMessage("NavBar.logOut")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

NavBar.propTypes = {
  handleMobileToggle: PropTypes.func.isRequired,
  asideMobileActive: PropTypes.bool,
};

export default NavBar;
